<template>
  <div class="warp-bg">
    <van-nav-bar
      title="搜索"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="search-bg">
      <form action="javascript:void 0">
        <input v-model="keyWord" type="search" class="input-search" placeholder="请输入目的地/景点/门票/酒店/餐饮等产品名称" @keyup.13="handleSearch">
      </form>
      <!-- <p><a href="javascript:;" @click="handleSearchClear">取消</a></p> -->
    </div>
    <div class="title">
      <p>热门搜索</p>
    </div>
    <div class="key-word">
      <div v-for="(item,index) of hotSearch" :key="index" class="key-text"><span :class="'col'+index">{{ index+1 }}</span><a href="javascript:;" @click="handleHotSearch(index)">{{ item.name }}</a></div>
    </div>
    <tabbar />
  </div>
</template>

<script>
import Tabbar from '@/components/tabBar'
import { Toast } from 'vant'

export default {
  name: 'Search',
  components: {
    Tabbar,
    [Toast.name]: Toast
  },
  data() {
    return {
      keyWord: '',
      hotSearch: [
        { name: '美丽延庆' },
        { name: '八达岭长城' },
        { name: '延庆' },
        { name: '百里画廊' },
        { name: '赏花' }
      ]
    }
  },
  methods: {
    handleSearch() {
      if (!this.keyWord) {
        Toast({
          message: '请输入目的地/景点/门票/酒店/餐饮等产品名称',
          duration: '1000'
        })
        return
      }
      const keyWord = this.keyWord
      this.$router.push({ path: `/searchList/${keyWord}/` })
    },
    handleSearchClear() {
      this.keyWord = ''
    },
    handleHotSearch(index) {
      const keyWord = this.hotSearch[index].name
      this.$router.push({ path: `/searchList/${keyWord}/` })
    },
    onClickLeft() {
      this.$router.go(-1)
    }
  }
}
</script>

<style lang="less" scoped>
@import '~@/styles/public.less';
input[type=search]::-webkit-search-cancel-button{
  -webkit-appearance: none;
}
.search-bg{
  height :0.6rem;
  .autoHeight();
  padding :0.2rem 5%;
  background: #fff;
  input::placeholder{
    color :@greyC9
  }
  .input-search{
    width :100%;
    height :0.8rem;
    padding-left :0.75rem;
    background :url('~@/assets/images/head_icon01.png') 4% center no-repeat #F6F8FA;
    background-size :6%;
    border-radius :0.6rem;
    color :#999;
    border :none;
  }
  p{
    line-height :0.6rem;
    color :@red;
    font-size : 0.3rem;
    float :left;
    margin-left :0.15rem;
  }
}
.title{
  padding :0.3rem 0.15rem 0;
  p{
    font-size : 0.26rem;
    line-height :0.8rem;
    color :@grey6;
    padding :0 0.15rem;
  }
}
.key-word{ width: 90%; padding: 0.2rem 2%; background: #fff; border-radius: 10px; box-shadow: 0 5px 10px rgba(195, 234, 255, 0.28); margin: 0 auto;
  .key-text{ font-size : 0.3rem;  margin-left :0.15rem; padding :0.2rem 0.3rem; line-height: 0.5rem; border-bottom: 1px #F7FBFF solid; display: flex;
    span{ width: 10%;}
    .col0{ color: #FF4545;}
    .col1{ color: #FD5F29;}
    .col2{ color: #F6AF73;}
    .col3{ color: #7FBF59;}
    .col4{ color: #6F85FF;}
    a{ color :@grey6;}
  }
}
</style>
